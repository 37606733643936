/**
 * Component to handle password entry.
 */
import classNames from 'classnames'
import React, { Component } from 'react'

import { Input, LockIcon, ToggleShowPassword } from './styles'

import type { ReactNode } from 'react'

export type Props = {
  children?: ReactNode
  onChange: (...args: Array<any>) => any
  onBlur?: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  sublabel?: ReactNode
  name?: string
  value?: string
  id?: string
  autoComplete?: string
  className?: string
  placeholder?: string
}

type State = {
  focused: boolean
  passwordShown: boolean
}

export default class PasswordInput extends Component<Props, State> {
  textWrapperRef: {
    current: HTMLDivElement | null
  }

  constructor(props: Props) {
    super(props)
    this.textWrapperRef = React.createRef()
  }

  state = {
    focused: false,
    passwordShown: false,
  }

  get className() {
    return classNames('amp-password-input', this.props.className)
  }

  get inputType() {
    return this.state.passwordShown ? 'text' : 'password'
  }

  get toggleGlyph() {
    return this.state.passwordShown ? 'hide' : 'show'
  }

  handleFieldBlur = (event: React.FocusEvent) => {
    const elementReceivingFocus =
      event.relatedTarget instanceof Element ? event.relatedTarget : null

    if (this.fieldWrapperContainsElement(elementReceivingFocus)) return

    this.setState({
      focused: false,
    })
    const blurHandler = this.props.onBlur || this.props.onChange
    blurHandler(event)
  }

  fieldWrapperContainsElement(element: Element | null | undefined): boolean {
    const textWrapperElement = this.textWrapperRef.current
    return !!textWrapperElement && textWrapperElement.contains(element)
  }

  handleFieldFocus = () => {
    this.setState({
      focused: true,
    })
  }

  handleLabelClick = (event: React.SyntheticEvent) => {
    const targetIsInput =
      event.target instanceof Element && event.target.nodeName === 'INPUT'

    if (this.state.focused && !targetIsInput) {
      event.preventDefault()
    }
  }

  togglePasswordShown = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  render() {
    const {
      autoComplete,
      hasError,
      id,
      label,
      name,
      onChange,
      placeholder,
      sublabel,
      value,
      className,
    } = this.props
    return (
      <Input
        id={id}
        type={this.inputType}
        placeholder={placeholder}
        name={name}
        value={value}
        autoComplete={autoComplete}
        onBlur={this.handleFieldBlur}
        onChange={onChange}
        hasError={hasError}
        label={label}
        sublabel={sublabel}
        className={className}
      >
        <LockIcon glyph="lock" />
        <ToggleShowPassword
          className="toggle-password-shown"
          glyph={this.toggleGlyph}
          onBlur={this.handleFieldBlur}
          onClick={this.togglePasswordShown}
          onFocus={this.handleFieldFocus}
        />
      </Input>
    )
  }
}
