/**
 * Component to handle text entry.
 */
import classNames from 'classnames'
import React, { Component } from 'react'

import TextInputLabel from '~/components/text-input-label/text-input-label.component'
import TextInputSublabel from '~/components/text-input-sublabel'

import {
  Container,
  Input,
  TextArea,
  InputWrapper,
  LabelWrapper,
} from './text-input.styles'

import type { ReactNode } from 'react'

export type Props = {
  disabled?: boolean
  onChange: (...args: Array<any>) => any
  onBlur?: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  sublabel?: ReactNode
  name?: string
  value?: string
  defaultValue?: string
  id?: string
  title?: string
  autoComplete?: string
  type?: 'text' | 'password' | 'email' | 'tel' | 'datetime-local' | 'time'
  className?: string
  placeholder?: string
  children?: ReactNode
  multiline?: boolean
  onClick?: () => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  readOnly?: boolean
  maxLength?: number
  minLength?: number
  inputRef?: React.MutableRefObject<any>
  variant?: 'primary' | 'secondary'
}

type State = {
  focused: boolean
}

export default class TextInput extends Component<Props, State> {
  static defaultProps = {
    type: 'text' as const,
  }

  state = {
    focused: false,
  }

  handleBlur = (event: React.SyntheticEvent) => {
    this.setState({
      focused: false,
    })
    const blurHandler = this.props.onBlur || this.props.onChange

    if (blurHandler) blurHandler(event)
  }

  handleFocus = () => {
    this.setState({
      focused: true,
    })
  }

  handleLabelClick = (event: React.SyntheticEvent) => {
    const targetIsInput =
      event.target instanceof Element && event.target.nodeName === 'INPUT'

    if (this.state.focused && !targetIsInput) {
      event.preventDefault()
    }
  }

  get fieldType() {
    return this.props.multiline ? TextArea : Input
  }

  render() {
    const {
      autoComplete,
      className,
      disabled,
      hasError,
      id,
      label,
      name,
      onChange,
      placeholder,
      sublabel,
      title,
      type,
      value,
      defaultValue,
      onClick,
      onKeyDown,
      readOnly,
      maxLength,
      minLength,
      inputRef,
      variant,
    } = this.props
    const fieldClass = classNames('amp-text-input', className)
    const FieldElement = this.fieldType as typeof Input & typeof TextArea
    return (
      <Container
        className={fieldClass}
        onMouseDown={this.handleLabelClick}
        htmlFor={id}
      >
        {label && (
          <LabelWrapper>
            <TextInputLabel disabled={disabled} hasError={hasError}>
              {label}
            </TextInputLabel>
            <TextInputSublabel>{sublabel}</TextInputSublabel>
          </LabelWrapper>
        )}
        <InputWrapper
          className="text-wrapper"
          $disabled={disabled}
          $hasError={hasError}
          $variant={variant}
        >
          <FieldElement
            onClick={onClick}
            autoComplete={autoComplete}
            disabled={disabled}
            $disabled={disabled}
            id={id}
            name={name}
            onBlur={this.handleBlur}
            onChange={onChange}
            onFocus={this.handleFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            title={title}
            type={type}
            value={value}
            defaultValue={defaultValue}
            readOnly={readOnly}
            maxLength={maxLength}
            minLength={minLength}
            ref={inputRef}
          />
        </InputWrapper>
        {this.props.children}
      </Container>
    )
  }
}
